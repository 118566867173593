
import Vue from "vue";
import BannerList from "@/components/organisms/BannerList.vue";
import ContestNotification from "@/components/organisms/ContestNotification.vue";
import WritingData from "@/components/organisms/WritingData.vue";
import NolaNovelData from "@/components/organisms/NolaNovelData.vue";
// import UserProfile from "@/components/organisms/UserProfile.vue";
import NovelList from "@/components/organisms/NovelList.vue";
import { showNotifyDialog } from "@/lib/dialog";
import { isTwitterLogin } from "@/lib/twitterAuth";
import { Auth } from "aws-amplify";
import { checkDeprecatedBrowser } from "@/lib/checkDeprecatedBrowser";
import isMobile from "ismobilejs";
import { format } from "date-fns";
import axiosBase from "axios";
import { News } from "@/lib/models/general";
import { ConnectedOtherServices, Novel } from "@/lib/models";
import MaskedLoading from "@/components/atoms/MaskedLoading.vue";
import { Dialog } from "@/lib/utils";
import AgentPromotionDialog from "@/components/ui/AgentPromotionDialog.vue";

const axios = axiosBase.create({
  baseURL: process.env.VUE_APP_MICROCMS_API_ENDPOINT,
  headers: { "X-API-KEY": process.env.VUE_APP_MICROCMS_API_KEY },
});

export default Vue.extend<Data, Methods, Computed, Props>({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: {
    // UserProfile,
    ContestNotification,
    WritingData,
    NolaNovelData,
    BannerList,
    NovelList,
    MaskedLoading,
  },
  data() {
    return {
      username: null,
      banner: [],
      news: [],
      contests: [],
      checkedTwitterLogin: false,
      isLoading: false,
    };
  },
  computed: {
    novel() {
      return (novelId) => this.$store.getters["novelModule/novel"](novelId);
    },
    novels() {
      return this.$store.getters["novelModule/novels"];
    },
    isPC() {
      return !isMobile().any;
    },
    isConnectedNolaNovel() {
      const connectedOtherServices = this.$store.getters["userModule/connectedOtherServices"] as ConnectedOtherServices;
      return connectedOtherServices.nolaNovel.result;
    },
    agentPopupDisplayFlagNovelId() {
      return this.$store.getters["generalModule/agentPopupDisplayFlagNovelId"];
    },
  },
  methods: {
    async checkTwitterLogin() {
      if (
        isTwitterLogin() &&
        (!this.novels || !this.novels.length) &&
        sessionStorage.getItem("TESTING") === undefined
      ) {
        if (
          window.confirm(
            "Twitterログインは新規ユーザーは利用できません。\nメールアドレスで会員登録、ログインしてください。\n\nログアウトしますか？"
          )
        ) {
          await Auth.signOut();
          window.localStorage.clear();
          // alert('Twitterログインは新規ユーザーは利用できません。\nメールアドレスで会員登録、ログインしてください。');
          window.location.href = "/lp";
        }
      }
    },
    async getNews() {
      const { isPC } = this;

      const bannerRequest = await axios.get(`/bannerweb?limit=${isPC ? 2 : 1}`);
      const newsRequest = await axios.get("/news?limit=1");

      if (bannerRequest.status !== 200 || newsRequest.status !== 200) {
        await showNotifyDialog({
          title: "エラー",
          content: "お知らせの取得に失敗しました。",
        });
      }

      this.banner = bannerRequest.data.contents;
      this.news = newsRequest.data.contents;

      const latestNews = this.news[0];
      this.$store.commit("generalModule/setLatestNews", latestNews);
    },
    changeLoading(value) {
      this.isLoading = value;
    },
  },
  async created() {
    const { $store } = this;

    /** 非推奨ブラウザへの文言表示 */
    checkDeprecatedBrowser();

    await $store.dispatch("userModule/initialize");
    $store.dispatch("novelModule/initialize");
    $store.dispatch("memoModule/initialize");
    $store.dispatch("manuscriptModule/initializeGeneralSettings");
    $store.dispatch("novelModule/initializeManuscriptSettings");

    if (this.isConnectedNolaNovel) {
      $store.dispatch("nolaNovelModule/fetchNovels");
    }

    const connectedOtherServices = this.$store.getters["userModule/connectedOtherServices"] as ConnectedOtherServices;
    if (connectedOtherServices.nolaNovel.result)
      this.$store.dispatch("nolaNovelModule/fetchAllNotifications", { isRead: false });

    await this.getNews();

    const currentDate = format(new Date(), "YYYY-MM-DD");

    const contestRequest = await axios.get(`/contest?filters=deadline[greater_than]${currentDate}&limit=1`);
    if (contestRequest.status !== 200) {
      await showNotifyDialog({
        title: "エラー",
        content: "コンテスト情報の取得に失敗しました。",
      });
    }
    this.contests = contestRequest.data.contents;

    // エージェント機能のポップアップ表示のフラグチェック
    if (this.agentPopupDisplayFlagNovelId) {
      const novel = this.novel(this.agentPopupDisplayFlagNovelId);

      // ポップアップ表示
      const agentPromotionDialog = new Dialog(AgentPromotionDialog);
      agentPromotionDialog.show({ novelTitle: novel!.title });

      // 表示済みとする
      await this.$store.dispatch("generalModule/addDisplayedAgentPopupForNovel", this.agentPopupDisplayFlagNovelId);

      // フラグを初期化する
      this.$store.commit("generalModule/setAgentPopupDisplayFlagNovelId", null);
    }
  },
  watch: {
    novels() {
      if (!this.checkedTwitterLogin) {
        this.checkTwitterLogin();
        this.checkedTwitterLogin = true;
      }
    },
  },
});

interface Props {}

interface Data {
  username: string | null;
  banner: object[];
  news: News[];
  contests: object[];
  checkedTwitterLogin: boolean;
  isLoading: boolean;
}

interface Computed {
  novel(novelId: string): Novel;
  novels: Novel[];
  isPC: boolean;
  isConnectedNolaNovel?: boolean;
  agentPopupDisplayFlagNovelId: string | null;
}

interface Methods {
  checkTwitterLogin(): void;
  getNews(): Promise<void>;
  changeLoading(value: boolean): void;
}
